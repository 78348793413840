<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/systemset/user' }">用户列表</el-breadcrumb-item>
                <el-breadcrumb-item>详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--详情-->
            <el-row>
                <el-col :span="16">
                    <el-form v-loading="loading" ref="form" :model="form" size="medium" :label-width="this.env.label_width">
                        <el-form-item label="工号">
                            <el-input v-if="user_uuid ===''" v-model="form.job_number"></el-input>
                            <span v-else>{{ form.job_number }}</span>
                        </el-form-item>
                        <el-form-item v-if="form.state != null" label="状态">
                            <el-tag size="mini" v-if="form.state===1" type="success">正常</el-tag>
                            <el-tag size="mini" v-if="form.state===2" type="warning">禁用</el-tag>
                        </el-form-item>
                        <el-form-item label="姓名">
                            <el-input v-model="form.name"></el-input>
                        </el-form-item>
                        <el-form-item label="手机号">
                            <el-input v-model="form.mobile"></el-input>
                        </el-form-item>
                        <el-form-item label="职位">
                            <el-input v-model="form.position"></el-input>
                        </el-form-item>
                        <el-form-item label="角色">
                            <el-select v-model="form.role_uuid" placeholder="请选择角色" style="width: 100%">
                                <el-option
                                        v-for="item in role_list"
                                        :key="item.role_uuid"
                                        :label="item.role_name"
                                        :value="item.role_uuid">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button v-if="is_auth('systemset.user.issave') && this.form.is_admin!==1"
                                       size="medium" type="primary" @click="save">保存
                            </el-button>
                            <el-button size="medium" @click="isreturn">返回</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '用户详情',
            loading: true,
            user_uuid: '',
            role_list: [],
            form: {
                name: '',
                job_number: '',
                mobile: '',
                position: '',
                role_uuid: '',
            }
        }
    },
    // 创建
    created() {
        this.init()
        this.getrole()
    },
    // 安装
    mounted() {

    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            let user_uuid = this.$route.query.user_uuid
            if (user_uuid !== undefined) {
                this.user_uuid = user_uuid
                this.getinfo();
            } else {
                this.loading = false
            }
        },
        // 获取角色信息
        getrole() {
            let postgetrole = {
                api_name: "systemset.user.getrole",
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('merchant', postgetrole, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.role_list = json.data
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 查询用户信息
        getinfo() {
            let postdetails = {
                api_name: "systemset.user.getinfo",
                token: this.Tool.get_l_cache('token'),
                user_uuid: this.user_uuid,
            }
            this.loading = true
            this.Tool.post_data('merchant', postdetails, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.form = json.data
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 保存
        save() {
            let postdata = {
                api_name: "systemset.user.issave",
                token: this.Tool.get_l_cache('token'),
                name: this.form.name,
                job_number: this.form.job_number,
                position: this.form.position,
                mobile: this.form.mobile,
                role_uuid: this.form.role_uuid,
            }
            // 判断是否有用户id，如果有，则是更新
            if (this.user_uuid !== '') {
                postdata.user_uuid = this.user_uuid
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.$router.push({path: '/systemset/user'})    // 返回列表
                        }
                    });
                } else {
                    this.$message.error(json.message);
                }
            })

        },
        // 返回
        isreturn() {
            this.$router.push({path: '/systemset/user'})
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
